<template>
    <div v-loading="loading">
        女：
        <el-table :data="list1" border>
            <el-table-column prop="materialName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="name" label="声音" width="180">
                <template slot-scope="scope">
                    <div class="iconplay">
                        <i v-show="!scope.row.playFlag" @click="handleplay(scope.row, 'play')"
                            class="el-icon-video-play"></i>
                        <i v-show="scope.row.playFlag" @click="handleplay(scope.row, 'pause')"
                            class="el-icon-video-pause"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        男：
        <el-table :data="list2" border>
            <el-table-column prop="materialName" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="name" label="声音" width="180">
                <template slot-scope="scope">
                    <div class="iconplay">
                        <div class="iconplay">
                        <i v-show="!scope.row.playFlag" @click="handleplay(scope.row, 'play')"
                            class="el-icon-video-play"></i>
                        <i v-show="scope.row.playFlag" @click="handleplay(scope.row, 'pause')"
                            class="el-icon-video-pause"></i>
                    </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <audio ref="audioref" loop :src="playurl"></audio>
    </div>
</template>

<script>
import { ttslist } from '@/api'
export default {
    data() {
        return {
            list1: [],
            list2: [],
            playurl: null,
            loading: false
        }
    },
    mounted() {
        this.getlist()
    },
    methods: {
        handleplay(val, type) {
            this.list1.filter(item => {
                item.playFlag = false
            })
            this.list2.filter(item => {
                item.playFlag = false
            })
            if (type == 'play') {
                this.playurl = val.soundUrl
                val.playFlag = true
                setTimeout(() => {
                    this.$refs.audioref.play()
                }, 200);
            } else {
                val.playFlag = false
                this.$refs.audioref.pause()
            }
        },
        getlist() {
            this.loading = true
            ttslist().then(res => {
                res.data.soundList.filter(item => {
                    item.extendParam = JSON.parse(item.extendParam)
                    if (item.extendParam.voiceName.split('-')[0] == '中文（中国）') {
                        item.playFlag = false
                        if (item.extendParam.voiceName.split('-')[1] == '女') {
                            this.list1.push(item)
                        } else {
                            this.list2.push(item)
                        }
                    }
                })
                console.log(this.list1);
            }).finally(()=>{
                this.loading = false
            })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-table__cell {
    text-align: center;
}

.iconplay {
    font-size: 25px;

    i {
        cursor: pointer;
    }
}
</style>